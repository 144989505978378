import React from "react"
import Page from "../../../components/page"
import ResponsiveImageGrid from "../../../components/responsive-image-grid"
import ScrollDown from "../../../components/scroll-down"
import { themes } from "../../../styles/themes"
import paths from "../../../utils/paths"

const goodVibrationsGrid = labels => [
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/good-vibrations/good-vibrations-lights-grace-jones.jpg",
        alt: labels.goodVibrationsCoquetteOdetteGraceJonesAlt,
        imgTitle: labels.coquetteOdetteImgTitle,
        mode: "full-screen"
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 32,
        src: "/images/good-vibrations/coquette-odette-sette.jpg",
        title: labels.goodVibrationsCoquetteOdetteSetteTitle,
        alt: labels.goodVibrationsCoquetteOdetteSetteAlt,
        imgTitle: labels.coquetteOdetteImgTitle
      },
      {
        type: "image",
        weight: 66.5,
        src:
          "/images/good-vibrations/coquette-odette-sette-gogo-dance-stage-gif.gif",
        title: labels.goodVibrationsCoquetteOdetteTitle,
        subtitle: labels.goodVibrationsCoquetteOdetteSubtitle,
        alt: labels.goodVibrationsCoquetteOdetteAlt,
        imgTitle: labels.coquetteOdetteImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/good-vibrations/whiskey-side-table-detail.jpg",
        title: labels.goodVibrationsWhiskeySideTableTitle,
        text: labels.goodVibrationsUnpluggedText,
        textClass: "good-vibrations-unplugged-text",
        alt: labels.goodVibrationsWhiskeySideTableAlt,
        imgTitle: labels.whiskeySideTableImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 49.25,
        src: "/images/good-vibrations/moon-side-table.jpg",
        title: labels.goodVibrationsMoonSideTableTitle,
        aspectRatio: "1 / 1",
        position: "bottom",
        alt: labels.goodVibrationsMoonSideTableAlt,
        imgTitle: labels.moonSideTableImgTitle
      },
      {
        type: "image",
        weight: 49.25,
        src: "/images/good-vibrations/moon-side-table-glass-detail.jpg",
        aspectRatio: "1 / 1",
        position: "bottom",
        alt: labels.goodVibrationsMoonSideTableGlassAlt,
        imgTitle: labels.moonSideTableImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/good-vibrations/read-between-the-lines-carpet.jpg",
        title: labels.goodVibrationsReadBetweenTheLinesTitle,
        titleColor: "black",
        alt: labels.goodVibrationsReadBetweenTheLinesAlt,
        imgTitle: labels.readBetweenTheLinesImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 49.25,
        src: "/images/good-vibrations/david-bowie-quilling.jpg",
        title: labels.goodVibrationsDavidBowieTitle,
        subtitle: labels.goodVibrationsDavidBowieSubtitle,
        alt: labels.goodVibrationsDavidBowieQuillingAlt,
        imgTitle: labels.davidBowieQuillingImgTitle
      },
      {
        type: "image",
        weight: 49.25,
        src: "/images/good-vibrations/dynamic-side-table-gold-gif.gif",
        title: labels.goodVibrationsDynamicSideTableTitle,
        alt: labels.goodVibrationsDynamicSideTableAlt,
        imgTitle: labels.dynamicSideTableImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/good-vibrations/easy-rider-triple.jpg",
        text: labels.goodVibrationsGoodBadSexyText,
        textClass: "good-vibrations-good-bad-sexy-text",
        alt: labels.goodVibrationsEasyRiderAlt,
        imgTitle: labels.easyRiderImgTitle
      }
    ]
  }
]

export default function GoodVibrations({ labels, ...props }) {
  return (
    <Page
      {...props}
      labels={labels}
      title={labels.goodVibrations}
      theme={themes(labels).ipsWhite(true)}
      description={labels.goodVibrationsDescription}
      keywords={labels.goodVibrationsKeywords}
      ogUrl={paths(labels).goodVibrations}
      ogImage="/images/og-images/good-vibrations.jpg"
      footerPadding
    >
      <ScrollDown arrowClassName="scroll-down-arrow-black" />
      <ResponsiveImageGrid grid={goodVibrationsGrid(labels)} />
    </Page>
  )
}
